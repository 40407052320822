<template lang="pug">
.template-chooser-banner-wrapper
  .row.align-items-stretch.h-100
    .col
      .d-flex.h-100.text-wrapper
        .col
          om-heading.mb-2.font-size-3(h3) {{ title }}
          om-body-text.line-break(bt400md) {{ description }}
        .row.buttons.justify-content-start
          om-button.ml-2.mr-3(
            v-if="hasStartEditingClickListener"
            primary
            small
            @click="$emit('startEditingClick')"
          ) {{ $t('templateChooser.banner.startEditing') }}
          om-button.mr-2(
            v-if="hasReadMoreClickListener"
            icon="book-alt"
            small
            @click="$emit('readMoreClick')"
          ) {{ $t('personalizedExperiences.buttons.readMore.title') }}
          om-button(
            v-if="hasWatchAVideoClickListener"
            icon="play-circle"
            small
            @click="$emit('watchAVideoClick')"
          ) {{ $t('personalizedExperiences.buttons.watch.title') }}
    .col.flex-grow-0.d-flex.align-items-center.pl-0
      slot(name="image")
</template>

<script>
  export default {
    props: {
      title: { type: String, required: true },
      description: { type: String, required: true },
    },
    computed: {
      hasStartEditingClickListener() {
        return this.$listeners && this.$listeners.startEditingClick;
      },
      hasReadMoreClickListener() {
        return this.$listeners && this.$listeners.readMoreClick;
      },
      hasWatchAVideoClickListener() {
        return this.$listeners && this.$listeners.watchAVideoClick;
      },
    },
  };
</script>

<style lang="sass">
  .template-chooser-banner-wrapper
    border: 1px solid #E3E5E8
    border-radius: 12px
  .text-wrapper
    padding: 20px 24px
  .line-break
    line-break: normal !important
  .buttons
    padding-left: 10px
</style>
